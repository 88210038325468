import React, { useRef, useState } from 'react';
import { Link } from "react-router-dom";
import "./Form.scss";
import emailjs from '@emailjs/browser';
import { ReactComponent as Logo } from "../Main/Logo.svg";

function Form() {
    const form = useRef();
    const [statusMessage, setStatusMessage] = useState("");
    const [name, setName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [contact, setContact] = useState('');
    const [message, setMessage] = useState('');
    const [tag, setTag] = useState('');
    const [selectedOption, setSelectedOption] = useState(null);
    
        const handleOptionChange = (event) => {
            setSelectedOption(event.target.value);
        };
    

    const sendEmail = (e) => {
      e.preventDefault();
      setName('');
      setContact('');
      setMessage('');
      setUserEmail('');
      setTag('');
  
      emailjs.sendForm('service_xu6ob72', 'template_csb6qup', form.current, 'SnMGpJWvx_zEMMA8J')
        .then((result) => {
            setStatusMessage("Заявка успешно отправлена! Скоро мы с вами свяжимся");
        }, (error) => {
            console.log(error.text);
        });
    };
    return ( 
        <div className="form">
            <div className="logo">
            <Link to="/"><Logo/></Link>
            </div>
            <h3>Давайте ближе познакомимся</h3>
            <p>Расскажите коротко о своих задачах, а мы придумаем решение.</p>
            <h4>{statusMessage}</h4>
            <div className="inputs">
                <form ref={form} onSubmit={sendEmail}>
                    <input type="text" name="name" placeholder="Имя" id="name" onChange={event => setName(event.target.value)} value={name}/>
                    <input type="email" name="useremail" placeholder="Почта" id="email" onChange={event => setUserEmail(event.target.value)} value={userEmail}/>
                    <input type="text" name="contact" placeholder="Телефон или телеграм" id="contact" onChange={event => setContact(event.target.value)} value={contact}/>
                    <textarea name="message" placeholder="Опишите задачу" id="" cols="30" rows="10" onChange={event => setMessage(event.target.value)} value={message}></textarea>
                    
                    <div className="tag--list">

                        <div className="tag--header">
                            <p>Бюджет проекта:</p>
                        </div>

                        <div className="tag-container">
                            <input
                                type="radio"
                                id="option1"
                                name="tag"
                                value="250-500 тысяч ₽"
                                checked={selectedOption === "250-500 тысяч ₽"}
                                onChange={(event) =>{
                                    handleOptionChange(event);
                                    setTag(event.target.value);
                                }}
                                className="tag-radio"
                            />
                            <label htmlFor="option1" className="tag-label">
                                <span>250-500 тысяч ₽</span>
                            </label>

                            <input
                                type="radio"
                                id="option2"
                                name="tag"
                                value="500 тыс — 1 млн ₽"
                                checked={selectedOption === "500 тыс — 1 млн ₽"}
                                onChange={(event) =>{
                                    handleOptionChange(event);
                                    setTag(event.target.value);
                                }}
                                className="tag-radio"
                            />
                            <label htmlFor="option2" className="tag-label">
                                500 тыс — 1 млн ₽
                            </label>

                            <input
                                type="radio"
                                id="option3"
                                name="tag"
                                value="1 — 2 млн ₽"
                                checked={selectedOption === "1 — 2 млн ₽"}
                                onChange={(event) =>{
                                    handleOptionChange(event);
                                    setTag(event.target.value);
                                }}
                                className="tag-radio"
                            />
                            <label htmlFor="option3" className="tag-label">
                                1 — 2 млн ₽
                            </label>

                            <input
                                type="radio"
                                id="option4"
                                name="tag"
                                value="от 2 млн ₽"
                                checked={selectedOption === "от 2 млн ₽"}
                                onChange={(event) =>{
                                    handleOptionChange(event);
                                    setTag(event.target.value);
                                }}
                                className="tag-radio"
                            />
                            <label htmlFor="option4" className="tag-label">
                                от 2 млн ₽
                            </label>

                        </div>

                    </div>
                    <button type="submit">Отправить</button>
                </form>
            </div>
        </div> 
    );
}

export default Form;